exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-templates-category-loadmore-js": () => import("./../../../src/templates/category/loadmore.js" /* webpackChunkName: "component---src-templates-category-loadmore-js" */),
  "component---src-templates-category-slot-review-cat-paginated-js": () => import("./../../../src/templates/category/slotReviewCatPaginated.js" /* webpackChunkName: "component---src-templates-category-slot-review-cat-paginated-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-games-slot-review-js": () => import("./../../../src/templates/games/slotReview.js" /* webpackChunkName: "component---src-templates-games-slot-review-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-default-js": () => import("./../../../src/templates/pages/default.js" /* webpackChunkName: "component---src-templates-pages-default-js" */),
  "component---src-templates-pages-register-js": () => import("./../../../src/templates/pages/register.js" /* webpackChunkName: "component---src-templates-pages-register-js" */),
  "component---src-templates-posts-default-js": () => import("./../../../src/templates/posts/default.js" /* webpackChunkName: "component---src-templates-posts-default-js" */)
}

